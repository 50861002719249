// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-community-code-of-conduct-mdx": () => import("./../../../src/pages/community/code-of-conduct.mdx" /* webpackChunkName: "component---src-pages-community-code-of-conduct-mdx" */),
  "component---src-pages-community-mdx": () => import("./../../../src/pages/community.mdx" /* webpackChunkName: "component---src-pages-community-mdx" */),
  "component---src-pages-design-mdx": () => import("./../../../src/pages/design.mdx" /* webpackChunkName: "component---src-pages-design-mdx" */),
  "component---src-pages-develop-animation-managers-mdx": () => import("./../../../src/pages/develop/animation/managers.mdx" /* webpackChunkName: "component---src-pages-develop-animation-managers-mdx" */),
  "component---src-pages-develop-animation-mdx": () => import("./../../../src/pages/develop/animation.mdx" /* webpackChunkName: "component---src-pages-develop-animation-mdx" */),
  "component---src-pages-develop-animation-nesting-mdx": () => import("./../../../src/pages/develop/animation/nesting.mdx" /* webpackChunkName: "component---src-pages-develop-animation-nesting-mdx" */),
  "component---src-pages-develop-core-mdx": () => import("./../../../src/pages/develop/core.mdx" /* webpackChunkName: "component---src-pages-develop-core-mdx" */),
  "component---src-pages-develop-design-breakpoints-mdx": () => import("./../../../src/pages/develop/design/breakpoints.mdx" /* webpackChunkName: "component---src-pages-develop-design-breakpoints-mdx" */),
  "component---src-pages-develop-design-mdx": () => import("./../../../src/pages/develop/design.mdx" /* webpackChunkName: "component---src-pages-develop-design-mdx" */),
  "component---src-pages-develop-design-multipliers-mdx": () => import("./../../../src/pages/develop/design/multipliers.mdx" /* webpackChunkName: "component---src-pages-develop-design-multipliers-mdx" */),
  "component---src-pages-develop-design-palette-mdx": () => import("./../../../src/pages/develop/design/palette.mdx" /* webpackChunkName: "component---src-pages-develop-design-palette-mdx" */),
  "component---src-pages-develop-mdx": () => import("./../../../src/pages/develop.mdx" /* webpackChunkName: "component---src-pages-develop-mdx" */),
  "component---src-pages-develop-sounds-accessibility-mdx": () => import("./../../../src/pages/develop/sounds/accessibility.mdx" /* webpackChunkName: "component---src-pages-develop-sounds-accessibility-mdx" */),
  "component---src-pages-develop-sounds-categories-mdx": () => import("./../../../src/pages/develop/sounds/categories.mdx" /* webpackChunkName: "component---src-pages-develop-sounds-categories-mdx" */),
  "component---src-pages-develop-sounds-mdx": () => import("./../../../src/pages/develop/sounds.mdx" /* webpackChunkName: "component---src-pages-develop-sounds-mdx" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-project-changelog-mdx": () => import("./../../../src/pages/project/changelog.mdx" /* webpackChunkName: "component---src-pages-project-changelog-mdx" */),
  "component---src-pages-project-contributing-architecture-mdx": () => import("./../../../src/pages/project/contributing/architecture.mdx" /* webpackChunkName: "component---src-pages-project-contributing-architecture-mdx" */),
  "component---src-pages-project-contributing-mdx": () => import("./../../../src/pages/project/contributing.mdx" /* webpackChunkName: "component---src-pages-project-contributing-mdx" */),
  "component---src-pages-project-contributing-playground-mdx": () => import("./../../../src/pages/project/contributing/playground.mdx" /* webpackChunkName: "component---src-pages-project-contributing-playground-mdx" */),
  "component---src-pages-project-contributing-website-mdx": () => import("./../../../src/pages/project/contributing/website.mdx" /* webpackChunkName: "component---src-pages-project-contributing-website-mdx" */),
  "component---src-pages-project-contributing-workflow-mdx": () => import("./../../../src/pages/project/contributing/workflow.mdx" /* webpackChunkName: "component---src-pages-project-contributing-workflow-mdx" */),
  "component---src-pages-project-faq-mdx": () => import("./../../../src/pages/project/faq.mdx" /* webpackChunkName: "component---src-pages-project-faq-mdx" */),
  "component---src-pages-project-guidelines-events-mdx": () => import("./../../../src/pages/project/guidelines/events.mdx" /* webpackChunkName: "component---src-pages-project-guidelines-events-mdx" */),
  "component---src-pages-project-guidelines-mdx": () => import("./../../../src/pages/project/guidelines.mdx" /* webpackChunkName: "component---src-pages-project-guidelines-mdx" */),
  "component---src-pages-project-guidelines-states-mdx": () => import("./../../../src/pages/project/guidelines/states.mdx" /* webpackChunkName: "component---src-pages-project-guidelines-states-mdx" */),
  "component---src-pages-project-guidelines-systems-mdx": () => import("./../../../src/pages/project/guidelines/systems.mdx" /* webpackChunkName: "component---src-pages-project-guidelines-systems-mdx" */),
  "component---src-pages-project-mdx": () => import("./../../../src/pages/project.mdx" /* webpackChunkName: "component---src-pages-project-mdx" */),
  "component---src-pages-project-purpose-mdx": () => import("./../../../src/pages/project/purpose.mdx" /* webpackChunkName: "component---src-pages-project-purpose-mdx" */),
  "component---src-pages-project-roadmap-mdx": () => import("./../../../src/pages/project/roadmap.mdx" /* webpackChunkName: "component---src-pages-project-roadmap-mdx" */)
}

